import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const tags = post.frontmatter.tags

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
      />
      <article className="mt-70px py-75px px-5 blog-post">
        <header className="max-w-700px mx-auto">
          <h1 className="text-3xl text-primary leading-1.2 mb-10px">
            {post.frontmatter.title}
          </h1>
          <span className="text-primary text-base leading-1.625 mb-5 block">
            {post.frontmatter.date} by &nbsp;
            {post.frontmatter.author.name}
          </span>
        </header>
        <section
          className="max-w-700px mx-auto"
          dangerouslySetInnerHTML={{
            __html: post.html,
          }}
          itemProp="articleBody"
        />
        <footer className="max-w-700px mx-auto border-t border-t-light-gray pt-5">
          {tags && (
            <span className="block text-primary text-base">
              Tagged With :&nbsp;
              {tags &&
                tags.map((tag, index) => {
                  return (
                    <div className="inline-block" key={index}>
                      <span className="text-primary text-base" key={index}>
                        {tag}
                      </span>
                      {tags.length - 1 !== index && <>,&nbsp;</>}
                    </div>
                  )
                })}
            </span>
          )}
        </footer>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        tags
        author {
          name
        }
        imageCover {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
